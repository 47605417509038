angular
    .module('annexaApp')
    .component('annexaBoxOperationsInvoices',{
        templateUrl: './components/tram/annexa-box-operations-invoices/annexa-box-operations-invoices.html',
        controller:['globalModals', 'Language', 'AnnexaEntityFactory', 'ModalService', '$rootScope', 'AnnexaObjectBoxFactory', 'AnnexaFormlyFactory', 'GlobalDataFactory', '$filter', '$scope', 'TramNewFactory', 'CustomFieldFactory', 'SecFactory', 
        function(globalModals, Language, AnnexaEntityFactory, ModalService, $rootScope, AnnexaObjectBoxFactory, AnnexaFormlyFactory, GlobalDataFactory, $filter, $scope, TramNewFactory, CustomFieldFactory, SecFactory) {
            var vm = this;
            vm.operationTypes = [];            
            this.$onInit = function () {
            	vm.proposal = ((vm.config && vm.config.proposal)?vm.config.proposal:undefined);
				if(TramNewFactory.procedureOperationTypes){
            		vm.operationTypes = TramNewFactory.procedureOperationTypes;
            	}
            	if(!vm.boxTitle) {
                    vm.boxTitle = 'global.operationsAndInvoices.title';
            	}
                vm.new = {};
                if(!vm.onlyAddOperations) {
                    vm.new.newFunc = function(item) {
	                	var modal = angular.copy(globalModals.createOperationInvoices);
	                    _.forEach(modal.wizard.steps, function (step, key) {
	                        modal.wizard.steps[key].annexaFormly.model = {};
	                        if(key == 1 || key == 2){
	                        	modal.wizard.steps[key].annexaFormly.model.archiveClassification = {};
	                        }
	                        modal.wizard.steps[key].annexaFormly.options = {};
	                    });
	                    if(item){
	                    	modal.extra = {item: item};
	                    	modal.wizard.steps[0].disabled = true;
	                    	modal.wizard.steps[0].annexaFormly.model.operationType = item.operationTypeCode;
	                    	if(item.operationTypeCode == 'OPERATION'){
	                    		modal.wizard.steps[1].disabled = false;
	                    		modal.wizard.steps[2].disabled = true;
	                    		modal.wizard.steps[1].annexaFormly.model.instanceCode = item.instanceCode;
	                    		modal.wizard.steps[1].annexaFormly.fields[10].templateOptions.value = item.instanceCode;
	                    		modal.wizard.steps[1].annexaFormly.model.operationType = item.operationType;
	                    		modal.wizard.steps[1].annexaFormly.model.subject = item.subject;
	                    		if(item.third){
	                    			modal.wizard.steps[1].annexaFormly.model.third = item.third;
	                    		}
	                    		modal.wizard.steps[1].annexaFormly.model.prevDef = {id:item.prevDef};
	                    		modal.wizard.steps[1].annexaFormly.model.createDocuments = {id:((item.operationTypeCode)?true:false)};
	                    		var amount = 0.00;
	                    		modal.wizard.steps[1].annexaFormly.model.budgetItems = [];
	                    		if(item.budgetitems && item.budgetitems.length > 0){
	                    			var i=1; 
	                    			_.forEach(item.budgetitems, function(itemAux){
	                    				modal.wizard.steps[1].annexaFormly.model.budgetItems.push({idAux:i++,  project:itemAux.project, budget:itemAux.budget, availableCredit:((itemAux && itemAux.project)?'':itemAux.budget.availableCredit), amount:itemAux.amount, amountAux:itemAux.amount, initialAmount:itemAux.amount, code:itemAux.code});
	                    				amount = amount + itemAux.amount;
	                   				});
	                    		}
	                    		modal.wizard.steps[1].annexaFormly.fields[11].templateOptions.value = ((amount)?amount:"0.00");
	                    	}else if(item.operationTypeCode == 'INVOICE'){
	                    		modal.wizard.steps[1].disabled = true;
	                    		modal.wizard.steps[2].disabled = false;
	                    	}
	                    }
	                    modal.wizard.modal = modal;
	                    var submitNewNotification = function () {
	                    	var self = this;
	                    	if(this.annexaFormly && this.annexaFormly.form && this.annexaFormly.form.$valid){
	                    		var createOperationInvoice = function(operationInvoice){
	                    			self.alerts.length = 0;
	                    			TramNewFactory.createOperationInvoice(operationInvoice).then(function (data) {
	                					vm.content.push(data);
	                					TramNewFactory.accountingReferences.push({name:data.accountingReference, code:data.instanceCode, operationType: ((data.operationType && data.operationType.id)?data.operationType.id:undefined)});
	                					self.close();
	                           	   }).catch(function (error) {
	                           		   if(error && error.data && error.data.message && error.data.message != 'Error call to PDI'){
	                           			   var parametersAux = error.data.message.split('--parametres--'); 
	                           			   if(parametersAux && parametersAux.length > 1){
	                           				   self.alerts.push({msg:parametersAux[1]});
	                           				   var showParamsModal = function(accountingParameters){
	                           					   var parameters = parametersAux[0].split(',');
	                            				   if(parameters && parameters.length > 0){
	                            					   	var modalParams = angular.copy(globalModals.createOperationInvoicesParameters);
	                            		              	modalParams.annexaFormly.model = {};
	 		                           		      		modalParams.annexaFormly.options = {};
	 		                           		      		modalParams.annexaFormly.options.formState = {readOnly: false};
	 		                           		      		var form = new AnnexaFormly();
	 		                           		      		var dataparams = {row: true,informed: true,colClass: ' col-sm-12',labelClass: 'label-strong'};
	 		                           		      		_.forEach(parameters, function(param){
	 		                           		      			var paramSplit = param.split('-paramName-');
	 		                           		      			var paramId = ((paramSplit && paramSplit.length > 0)?paramSplit[0]:param);
	 		                           		      			var paramName = ((paramSplit && paramSplit.length > 1)?paramSplit[1]:((paramSplit && paramSplit.length > 0)?paramSplit[0]:param));
	 		                           		      			var field = undefined; 
	 		                           		      			if(accountingParameters && accountingParameters[paramId] && Array.isArray(accountingParameters[paramId])){
	 		                           		      				var values = [];
	 		                           		      				_.forEach(accountingParameters[paramId],function(item){
	 		                           		      					values.push({id:item.id, name:((item.name)?item.name:item.id)});
	 		                           		      				});
	 		                           		      				field = form.createField(
		 		                           		      				paramId,
		 		                           		                    'annexaSelectRow',
		 		                           		                    'col-sm-12',
		 		                           		                    new AnnexaFormlyFieldSelectTemplateOptions(
		 		                           		                    	paramName,
		 		                           		                        'id',
		 		                           		                        'name',
		 		                           		                        values,
		 		                           		                        true
		 		                           		                    ),
		 		                           		                    dataparams
		 		                           		                );
	 		                           		      			}else{
		 		                           		      			field = form.createField(
		 		                           		      				paramId,
		                                                           'annexaInputRow',
		                                                           'col-sm-12',
		                                                           new AnnexaFormlyFieldTemplateOptions(
		                                                               'text',
		                                                               paramName,
		                                                               true,
		                                                               undefined,
		                                                               undefined,
		                                                               250
		                                                           ),
		                                                           dataparams
		 		                           		      			);
		 		                           		      			field.templateOptions.updateOnBlur = true;
	 		                           		      			}
	 		                           		      			modalParams.annexaFormly.fields.push(field);
	 		                           		      		});
	 		                           		      		var submitParamsFunction = function () {
	 		                           		      			var selfParams = this;
	 		                           		      			_.forEach(parameters, function(param){
	 		                           		      				var paramSplit = param.split('-paramName-');
	 		                           		      				var paramId = ((paramSplit && paramSplit.length > 0)?paramSplit[0]:param);
	 		                           		      				operationInvoice[paramId] = selfParams.annexaFormly.model[paramId];
	 		                           		      				if(operationInvoice[paramId] && operationInvoice[paramId].id){
	 		                           		      					operationInvoice[paramId] = operationInvoice[paramId].id;
	 		                           		      				}
	 		                           		      			});
	 		                           		      			selfParams.close();
	 		                           		      			createOperationInvoice(operationInvoice);
	 		                           		      		};
	 		                           		      		AnnexaFormlyFactory.showModal("modalOperationParams", modalParams, submitParamsFunction, false, undefined, 'global.literals.accept');
	                            				   }
	                           				   }
	                           				   TramNewFactory.getAccountingParameters().then(function (data) {
	                           					   showParamsModal(JSOG.decode(data));
	                           				   }).catch(function (error) {
	                           					   showParamsModal({});   
	                           				   });
	                           			   }else{
	                           				   self.alerts.push({msg:error.data.message});
	                           			   }
	                           		   }else{
	                           			   self.alerts.push({msg:$filter('translate')('global.operationsAndInvoices.error')});
	                           		   }
	                               });
	                    		}
	                    		var modelOperationType = this.wizard.steps[0].annexaFormly.model;
	                    		var modelOperation = this.wizard.steps[1].annexaFormly.model;
	                    		var modelInvoice = this.wizard.steps[2].annexaFormly.model;
	                    		var stepModelOperation = this.wizard.steps[1];
	                    		if(modelOperationType && modelOperationType.operationType && modelOperationType.operationType == 'OPERATION'){
	                    			if(modelOperation){
	                    				var budgetItemsField = this.wizard.steps[1].annexaFormly.fields[10];
	                    				var amountField = this.wizard.steps[1].annexaFormly.fields[11];
	                    				var parentOperationOk = true;
	                    				if(modelOperation && modelOperation.operationType && modelOperation.operationType.parentOperation == 'REQUIRED'){
	                    					if(!modelOperation.accountingReference || !modelOperation.accountingReference.id){
	                    						parentOperationOk = false;
	                    					}
	                    				}else if(modelOperation && modelOperation.operationType && modelOperation.operationType.parentOperation == 'NONE'){
	                    					modelOperation.accountingReference = undefined;
	                    				}
	                    				if(parentOperationOk){
	                    					var budgetItemsOk = true;
		                    				if(budgetItemsField && budgetItemsField.data && budgetItemsField.data.budgetItems && budgetItemsField.data.budgetItems.length > 0){
		                    					_.forEach(budgetItemsField.data.budgetItems, function(item){
		                    						if(!item.amount && (!item.budget || !angular.isString(item.budget))){
		                    							budgetItemsOk = false;
		                    						}
		                    					})
		                    				}else{
		                    					budgetItemsOk = false;
		                    				}
		                    				if(budgetItemsOk){
		                    					var operation = {
			                						instanceCode: ((TramNewFactory.userAccountInstances && TramNewFactory.userAccountInstances.length == 1)?TramNewFactory.userAccountInstances[0].code:((modelOperation.instanceCode)?modelOperation.instanceCode.code:undefined)),
			                						dossier: ((vm.config && vm.config.dossier)?{id:vm.config.dossier.id}:undefined),
			                						operationType: ((modelOperation.operationType)?{id:modelOperation.operationType.id}:undefined),
			                						operationTypeCode: modelOperationType.operationType,
			                						subject: ((modelOperation.subject)?modelOperation.subject:undefined),
			                						third: ((modelOperation.third && modelOperation.third.id)?{id:modelOperation.third.id}:undefined),
			                						prevDef: (($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.operations_invoices_conf && $rootScope.app.configuration.operations_invoices_conf.modifyPreviousDefinitive)?((modelOperation.prevDef)?modelOperation.prevDef.id:undefined):(($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.operations_invoices_conf && $rootScope.app.configuration.operations_invoices_conf.perviousDefinitiveDefault)?$rootScope.app.configuration.operations_invoices_conf.perviousDefinitiveDefault:"")),
			                						areGes: ((modelOperation.areGes && modelOperation.areGes.code)?modelOperation.areGes.code:undefined),
			                						amount: ((amountField && amountField.templateOptions && amountField.templateOptions.value)?amountField.templateOptions.value:"0.00"),
			                						createDocument: ((modelOperation.createDocuments)?modelOperation.createDocuments.id:false),
			                						accountingReferenceParent: ((modelOperation.accountingReference && modelOperation.accountingReference.id)?modelOperation.accountingReference.id:undefined),
			                						budgetItems:[]
			                    				}
			                            		if(budgetItemsField && budgetItemsField.data && budgetItemsField.data.budgetItems && budgetItemsField.data.budgetItems.length > 0){
			                            			_.forEach(budgetItemsField.data.budgetItems, function(item){
														var projectToAdd = undefined;
			                            				if(budgetItemsField.data.allProjects && item.project){
															projectToAdd = $linq(budgetItemsField.data.allProjects).firstOrDefault(undefined, "x => x.code == '"+item.project+"'");
															if(!projectToAdd){
																projectToAdd = $linq(budgetItemsField.data.allProjects).firstOrDefault(undefined, function(x){
																	if(x && x.code && item.project.startsWith(x.code)){
																		return true;
																	}else{
																		return false;
																	}	
																});
																if(projectToAdd){
																	projectToAdd = angular.copy(projectToAdd);
																	if(projectToAdd.expedientes && projectToAdd.expedientes.length > 1){
																		var exp = item.project.substring(projectToAdd.code.length+1, item.project.length);
																		if(exp){
																			var expAux = $linq(projectToAdd.expedientes).firstOrDefault(undefined, "x => x.numExpe == '"+exp+"'");
																			if(expAux){
																				projectToAdd.expediente = expAux;
																			}
																		}
																	}		
																}
															}else{
																projectToAdd = angular.copy(projectToAdd);
															}
			                            				}
														var budgetToAdd = $linq(budgetItemsField.data.budgets).firstOrDefault({code:item.budget}, "x => x.code == '"+item.budget+"'");
			                            				budgetToAdd = angular.copy(budgetToAdd);
			                            				budgetToAdd.numLine = item.numLine;
			                            				budgetToAdd.reference = item.reference;
			                            				budgetToAdd.operationReference = item.operationReference;
			                            				operation.budgetItems.push({code:undefined, project:projectToAdd, budget:budgetToAdd, amount:item.amount});
			                            			});
			                            		}
		                    					var validDocument = true;
			                            		if(operation.createDocument){
			                            			var calculateCFTGroups = function(newGroups){
			                	                    	var groups = [];
			                	                    	if(newGroups){
			                	                    		_.forEach(newGroups, function(ng){
			                	                    			_.forEach(ng.groups, function(g){
			                	                    				g.groups = calculateCFTGroups(g.newGroups); 
			                	                    				g.relatedCustomField = ((g.relatedCustomField && g.relatedCustomField.id)?{id:g.relatedCustomField.id}:undefined);
			                	                    				groups.push(g);
			                	                    			})
			                	                    		})
			                	                    	}
			                	                    	return groups;
			                	                    }
			                	                	if(modelOperation.archiveClassification &&  modelOperation.archiveClassification.$selected && modelOperation.archiveClassification.$selected.id){
				                            			operation.document = {
				                            					name: modelOperation.documentTitle,
				                            					type:{id:modelOperation.docType},
				                            					section:{id:modelOperation.section},
				                            					profiles:[{profile:{id:modelOperation.profile}}],
				                            					archiveClassification:{id:modelOperation.archiveClassification.$selected.id},
				                            					dossierTransactionDocuments:[{dossierTransaction:{id:modelOperation.dossierTransaction}}]
				                            			}
				                            			var cf = CustomFieldFactory.getModelValues(modelOperation);
				                            			operation.document.customFields = [];
				                	                    var documentType = $linq(GlobalDataFactory.allDocumentTypes).firstOrDefault(undefined,"x => x.id == "+modelOperation.docType);
				                	        			if(documentType && cf && cf.length > 0) {
				                	                        _.forEach(documentType.customFields, function(docTypeField) {
				                	                        	var groupField = undefined;
																if(stepModelOperation && stepModelOperation.annexaFormly && stepModelOperation.annexaFormly.fields){
																	groupField = $linq(stepModelOperation.annexaFormly.fields).firstOrDefault(undefined, "x => x.key == 'cf_"+documentType.id+"_"+docTypeField.customField.id+"'");
																}
								                            	var docCustomField = undefined;
								                            	if(groupField && groupField.data && groupField.data.customField){
								                            		docCustomField = angular.copy(groupField.data.customField);
								                            	}else{
								                            		docCustomField = angular.copy(docTypeField);	
								                            	}
																docCustomField.id = undefined;
																docCustomField.customField = { id: docTypeField.customField.id };
																docCustomField.documentType = { id: documentType.id };
																docCustomField.groups = calculateCFTGroups(docCustomField.newGroups);
				                	                            var cfValue = $linq(cf).singleOrDefault(undefined, "x => x.id == 'cf_" + documentType.id  + "_" + docTypeField.customField.id + "'");
				                	
				                	                            if(cfValue) {
				                	                                if(docTypeField.customField.frontendType == 'CHECKBOX'){
				                	                                    var custom_field_selected = $linq(cf).where(function(x){
				                	                                        if(x.id.startsWith(cfValue.id+"_")){return true}else{return false}}
				                	                                    ).toArray();
				                	                                    if(custom_field_selected && custom_field_selected.length > 0){
				                	                                        docCustomField.value = '[';
				                	                                        _.forEach(custom_field_selected, function (item, key) {
				                	                                            if(item.value) {
				                	                                                if(docTypeField.customField && docTypeField.customField.listValues && docTypeField.customField.listValues.length > 0) {
				                	                                                    var custom_field_value_selected = $linq(docTypeField.customField.listValues).where(function(x){
				                	                                                        if(item.id.endsWith("_"+x.value)){return true}else{return false}}
				                	                                                    ).toArray();
				                	                                                    if(custom_field_value_selected && custom_field_value_selected.length > 0){
				                	                                                        docCustomField.value += ((docCustomField.value == '[') ?custom_field_value_selected[0].value : ',' + custom_field_value_selected[0].value);
				                	                                                    }
				                	                                                }
				                	                                            }
				                	                                        });
				                	                                        docCustomField.value += ']';
				                	                                    }
				                	                                }else if(Array.isArray(cfValue.value)) {
				                	                                    if(docTypeField.customField.frontendType == 'MULTIPLESELECT') {
				                	                                        docCustomField.value = '[';
				                	
				                	                                        _.forEach(cfValue.value, function (item) {
				                	                                            docCustomField.value += ((docCustomField.value == '[') ? item : ',' + item);
				                	                                        });
				                	
				                	                                        docCustomField.value += ']';
				                	                                    } else {
				                	                                        docCustomField.value = cfValue.value[0];
				                	                                    }
				                	                                } else {
				                	                                    if(cfValue.value instanceof Date) {
				                	                                        docCustomField.value = $filter('date')(cfValue.value,'yyyy-MM-ddTHH:mm:ss');
				                	                                    } else {
				                	                                        docCustomField.value = cfValue.value;
				                	                                    }
				                	                                }
				                	                            }
				                	
				                	                            operation.document.customFields.push(docCustomField);
				                	                        });
				                	                    }
			                            			}else{
			                    						self.alerts.push({msg:$filter('translate')('global.operationsAndInvoices.errorNoArchiveClassification')});
			                    						validDocument = false;
			                    					}
			                            		}
			                            		if(validDocument){
			                            			createOperationInvoice(operation);
			                            		}
		                    				}else{
		                    					self.alerts.push({msg:$filter('translate')('global.operationsAndInvoices.noBudgetItems')});
		                    				}
	                    			}else{
	                    				self.alerts.push({msg:$filter('translate')('global.operationsAndInvoices.noParentOperation')});
	                    			}
	                    			}
	                    		}else if(modelOperationType && modelOperationType.operationType && modelOperationType.operationType == 'INVOICE'){
	                    			if(modelInvoice){
	                    				this.close();
	                    			}
	                    		}
	                    	}
	                    }
	                    $scope.$on('modifyBudgetItemsAccountingReferenceSelectedError', function(event, args) {
	                        modal.alerts.push({msg: $filter('translate')('global.operationsAndInvoices.errorAccountingReference')});
	                    });
	                    AnnexaFormlyFactory.showModal('modalCreateOperationInvoices', modal, submitNewNotification, false, true);    
	                };
				}else{
					vm.new.newFunc = function(item) {
	                	var modal = angular.copy(globalModals.createProposalOperationInvoices);
	                    modal.annexaFormly.model = {};
                        modal.annexaFormly.model.modal_body = {extra:((vm.config && vm.config.proposal)?vm.config.proposal.id:undefined), addedOperations:((vm.config && vm.config.proposal && vm.config.proposal.operations)?$linq(vm.config.proposal.operations).select("x => x.operation.accountingReference").toArray():[])};
                        modal.annexaFormly.options = {};
                        modal.extra = {proposal: ((vm.config && vm.config.proposal)?vm.config.proposal:undefined)};
                        var submitNewNotification = function () {
	                    	var self = this;
	                    	if(this.annexaFormly && this.annexaFormly.form && this.annexaFormly.form.$valid){
                    			self.alerts.length = 0;
								var proposalOperation = {
									proposal: {id:((self.extra && self.extra.proposal && self.extra.proposal.id)?self.extra.proposal.id:undefined)},
									operation: {
										accountingReference:((this.annexaFormly.model && this.annexaFormly.model.modal_body && this.annexaFormly.model.modal_body.operation && this.annexaFormly.model.modal_body.operation.id)?this.annexaFormly.model.modal_body.operation.id:undefined),
										instanceCode:((this.annexaFormly.model && this.annexaFormly.model.modal_body && this.annexaFormly.model.modal_body.operation && this.annexaFormly.model.modal_body.operation.instance)?this.annexaFormly.model.modal_body.operation.instance:undefined)
									}
								}
								if(proposalOperation.proposal && proposalOperation.proposal.id && proposalOperation.operation && proposalOperation.operation.accountingReference && proposalOperation.operation.instanceCode){
									SecFactory.createProposalOperation(proposalOperation).then(function (data) {
	                					vm.content.push(data.operation);
										if(self.extra.proposal && !self.extra.proposal.operations){
											self.extra.proposal.operations = [];
											self.extra.proposal.operations.push(data);
	                					}else{
											self.extra.proposal.operations.push(data);
	                					}
										self.close();
	                           	    }).catch(function (error) {
	                       			    self.alerts.push({msg:$filter('translate')('global.operationsAndInvoices.error')});
	                                });	
								}else{
									self.alerts.push({msg:$filter('translate')('global.operationsAndInvoices.error')});
								}
                    			
	                    	}
	                    }
	                    AnnexaFormlyFactory.showModal('modalCreateProposalOperationInvoices', modal, submitNewNotification, false);
	                        
	                };
				}
            }
        }],
        bindings: {
            boxTitle: '@?',
            permissions: '=',
            content: '=',
            isEdit: '=',
            config: '=',
            origin: '@',
            emptyText: '@',
			newTitle: '@?',
			onlyAddOperations: '=?',
			canRemoveOperations: '=?'
        }
    })
    .component('annexaBoxOperationsInvoicesRender', {
        templateUrl: './components/tram/annexa-box-operations-invoices/annexa-box-operations-invoices-render.html',
        require: {
            objectBoxComponent: '^^annexaObjectBox',
        },
        controller:['SecFactory', '$filter', 'DialogsFactory', '$rootScope', 'Language', 'AnnexaFormlyFactory', '$scope', '$state', 'TramNewFactory', 'GlobalDataFactory','globalModals', 'AnnexaModalFactory', 'SecFactory', 
        function(SecFactory, $filter, DialogsFactory, $rootScope, Language, AnnexaFormlyFactory, $scope, $state, TramNewFactory,GlobalDataFactory, globalModals, AnnexaModalFactory, SecFactory) {
        	var vm = this;
        	vm.languageColumn = Language.getActiveColumn();
        	vm.viewDossierOperationInvoice = function(item){
        		if(item && item.id){
        			TramNewFactory.getOperationInvoice(item.id).then(function (data) {
        				var modal = angular.copy(globalModals.seeOperationInvoices);
        				modal.data = data;
						if(modal.data && modal.data.operationType && modal.data.operationType[vm.languageColumn] && modal.data.operationSign && modal.data.operationSign == '1'){
							modal.data.operationType[vm.languageColumn] = $filter('translate')('global.operationsAndInvoices.cancellationOf')+" "+modal.data.operationType[vm.languageColumn]; 
						}
        				if(modal.data && modal.data.third){
        					var name = ((modal.data.third && modal.data.third.thirdType == "PHISICAL" && modal.data.third.name)?modal.data.third.name:"");
                    		name = name + ((modal.data.third && modal.data.third.thirdType == "PHISICAL" && modal.data.third.surename1)?(((modal.data.third.surname1Particles)?" "+modal.data.third.surname1Particles:"")+" "+modal.data.third.surename1):"");
                    		name = name + ((modal.data.third && modal.data.third.thirdType == "PHISICAL" && modal.data.third.surename2)?(((modal.data.third.surname2Particles)?" "+modal.data.third.surname2Particles:"")+" "+modal.data.third.surename2):"");
                    		name = name +((modal.data.third && modal.data.third.thirdType != "PHISICAL" && modal.data.third.corporateName)?modal.data.third.corporateName:"");
                    		modal.data.third.completename = name;
        				}
        				if(modal.data.prevDef){
        					var prevDef = $linq(GlobalDataFactory.previousDefinitive).firstOrDefault(undefined,"x => x.id == '"+modal.data.prevDef+"'");
        					var name = ((prevDef)?prevDef.name:undefined);
        					if(name){
        						name = $filter('translate')('global.operationsAndInvoices.'+name);
        					}else{
        						name = modal.data.prevDef;
        					}
        					modal.data.prevDefAux = name;
        				}else{
        					modal.data.prevDefAux = modal.data.prevDef;
        				}
        				if(modal.data.createDocument){
        					modal.data.createDocumentAux = $filter('translate')('global.literals.yes');
        				}else{
        					modal.data.createDocumentAux = $filter('translate')('global.literals.no');
        				}
        				if(!modal.data.amount){
        					modal.data.amount = 0.00;
        				}
						modal.withProject = vm.isProjectInstance(modal.data.instanceCode);
        				modal.languageColumn = vm.languageColumn;
        				modal.submitFunction = function() {
                            var self = this;
                            self.alerts.length = 0;
                            self.close();
                        }
                        AnnexaModalFactory.showModal('seeOperationInvoices', modal);
        			}).catch(function (error) {
                	}); 
        		}
        	}
			
			vm.deleteDossierOperationInvoice = function(item){
				if(item && item.id && vm.proposal && vm.proposal.operations){
					var operation = $linq(vm.proposal.operations).firstOrDefault(undefined,"x=>x.operation && x.operation.id == "+item.id);
					if(operation && operation.id){
						SecFactory.deleteProposalOperation(operation.id).then(function (data) {
							var index = $linq(vm.content).indexOf("x => x.id =="+item.id);
	                        if(index >= 0){
	                        	vm.content.splice(index, 1);
	                        }        					
							var index = $linq(vm.proposal.operations).indexOf("x => x.operation && x.operation.id =="+item.id);
	                        if(index >= 0){
	                        	vm.proposal.operations.splice(index, 1);
	                        }                   	
						}).catch(function (error) {});	
					}
				}
			}
        	
			vm.isUserInstance = function(instanceCode){
        		if(TramNewFactory.userAccountInstances && TramNewFactory.userAccountInstances.length > 0){
        			return ($linq(TramNewFactory.userAccountInstances).count("x => x.code == '"+instanceCode+"'") > 0);
        		}else{
        			return false;
        		}
        	}

			vm.isProjectInstance = function(instanceCode){
        		if(TramNewFactory.userAccountInstances && TramNewFactory.userAccountInstances.length > 0){
        			return ($linq(TramNewFactory.userAccountInstances).count("x => x.code == '"+instanceCode+"' && x.withProject") > 0);
        		}else{
        			return false;
        		}
        	}
        }],
        bindings: {
            content: '=',
            emptyText: '@',
            isEdit: '=',
            origin: '@',
			canRemoveOperations: '=?',
			proposal: '=?'
        }
    })