/**
 * Created by apop on 06/09/2018.
 */
angular
    .module('annexaApp')
    .component('annexaCompleteGuidedDossierTransaction', {
        templateUrl: './components/tram/annexa-complete-guided-dossier-transaction/annexa-complete-guided-dossier-transaction.html',
        controller: ['Language', 'TramNewFactory','$rootScope', 'GlobalDataFactory', '$filter', 'globalModals', 'AnnexaFormlyFactory', 'DialogsFactory', 'AnnexaPermissionsFactory', 'apiAdmin', 'RestService', function (Language, TramNewFactory, $rootScope, GlobalDataFactory, $filter, globalModals, AnnexaFormlyFactory, DialogsFactory, AnnexaPermissionsFactory, apiAdmin, RestService) {
            var vm = this;
            vm.languageColumn = "language1";
			vm.descriptionLanguageColumn = "descriptionLanguage1";
			vm.profile = '';
			vm.transactionsTypes = [];
			vm.tramitationPhases = [];
			vm.expirationTypes = [];
			vm.daysComputed = []; 
			vm.haveFinalTransaction = false
			vm.showUsersProfiles = false;
			vm.isParallelClose = false;
			vm.isRequiredAmendmentReason = true;
			vm.nextLevel = 1;
			vm.amendmentFiscalizationTransactionTypeId = undefined;
			vm.amendmentFiscalizationTransactionTypeFreeId = undefined;
            vm.addProfileFree = function (dossierTransactionInfo) {
                if (dossierTransactionInfo.graphNodeType == "TransactionFreeNormal" || dossierTransactionInfo.graphNodeType == "TransactionFreeFinal" || dossierTransactionInfo.graphNodeType == "SubprocessFreeNormal") {
                    if (dossierTransactionInfo.profiles && dossierTransactionInfo.profiles.length > 0) {
                        dossierTransactionInfo.profile = dossierTransactionInfo.profiles[0][vm.languageColumn];
                    }
                }
                if (dossierTransactionInfo.dossiersTransactionsInfo) {
                    _.forEach(dossierTransactionInfo.dossiersTransactionsInfo, function (value, key) {
                        vm.addProfileFree(value);
                    });
                }
            };

            vm.loadProfilesFunction = function (value) {
                if (!value || !value.val) {
                    return [];
                } else if (value.val != '*' && value.val.length < 3) {
                    return [];
                } else {
                    if (value.val == '*') {
                        value.val = '';
                    }
                    return RestService.filterData('common', 'Profile', {}, {search: value.val, langColumn:vm.languageColumn}, 0, 10, '', 3).then(function (data) {
                        var items = [];
                        if (data.data && data.data.content) {
                            _.forEach(JSOG.decode(data.data.content), function (item) {
                                items.push({id: item.id, value: item[vm.languageColumn], userProfiles:item.userProfiles});
                            })
                        }
                        return items;
                    }).catch(function () {
                        return [];
                    });
                }
            };
            
            vm.selectTransactionType = function (dossierTransactionInfo, dossierTransactionInfoAux) {
            	dossierTransactionInfoAux.dossierTransactionDefinition.expireType = dossierTransactionInfoAux.transactionType.expireType;
            	dossierTransactionInfoAux.dossierTransactionDefinition.maxDays = dossierTransactionInfoAux.transactionType.days;
            	vm.tramitationPhases = $linq(dossierTransactionInfoAux.transactionType.phases).select("x => x.tramitationPhase").toArray();
            	if(vm.tramitationPhases.length > 0){
                    dossierTransactionInfoAux.dossierTransactionDefinition.phase = vm.tramitationPhases[0];
            	}
            };

            vm.getUserProfiles = function (processProfile) {
            	var userProfiles = [];
            	_.forEach(processProfile, function (value, key) {
            		_.forEach(value.profile.userProfiles, function (value, key) {
            			var exist = false;
            			for(var i = 0; i < userProfiles.length; i++) {
            				if (userProfiles[i].user.id == value.user.id) {
                        		exist = true;
                			}
            			}
            			if(!exist) {
            				userProfiles.push(value);
            			}
            		});
                });
            	return userProfiles;
            }
            
            vm.getUserProfilesDTIProfile = function (profile) {
            	var userProfiles = [];
            	if(profile && profile.userProfiles){
            		_.forEach(profile.userProfiles, function (value, key) {
            			userProfiles.push(value);
            		});
            	}
            	return userProfiles;
            }
            
            vm.selectType = function (type, dossierTransactionInfo, actual) {
                if (actual && dossierTransactionInfo.graphNodeType != 'SelectOne' && dossierTransactionInfo.graphNodeType != 'SelectMultipleOpen') {
                    return false
                } else {
                    if (type == 1) {
                        if (dossierTransactionInfo.graphNodeType == 'SelectMultipleOpen') {
                            return true;
                        } else {
                            return false;
                        }

                    } else if (type == 2) {
                        if (dossierTransactionInfo.graphNodeType == 'SelectOne') {
                            return true;
                        } else {
                            return false;
                        }
                    } else {
                        if (vm.selectType(1, dossierTransactionInfo, actual) || vm.selectType(2, dossierTransactionInfo, actual)) {
                            return false;
                        } else {
                            return true;
                        }
                    }
                }
            };

            vm.isFree = function (dossierTransactionInfo) {
                if (dossierTransactionInfo && dossierTransactionInfo.graphNodeType == "TransactionFreeNormal" || dossierTransactionInfo.graphNodeType == "TransactionFreeFinal" || dossierTransactionInfo.graphNodeType == "SubprocessFreeNormal") {
                    return true;
                } else {
					if(dossierTransactionInfo && dossierTransactionInfo.noResponsibleProfileAndInheritWithResponsibleProfile){
		                return true;
    				}else{
		                return false;
					}
                }
            };

            vm.isSubprocessFree = function (dossierTransactionInfo) {
                if (dossierTransactionInfo.graphNodeType == "SubprocessFreeNormal") {
                    return true;
                } else {
                    return false;
                }
            };

            vm.clickObject = function (dossierTransactionInfo, dossierTransactionInfoAux) {
                if (!dossierTransactionInfoAux) {
                    dossierTransactionInfo.active = true;
                } else {
                    if (dossierTransactionInfo.dossiersTransactionsInfo) {
                        _.forEach(dossierTransactionInfo.dossiersTransactionsInfo, function (value, key) {
                            if (dossierTransactionInfoAux.graphNodeId != value.graphNodeId) {
                                value.active = false;
                            }
                        });
                    }
                }
            };
            var isReparation = function(dti){
            	var isRep = false;
            	if(dti && dti.dossiersTransactionsInfo && dti.dossiersTransactionsInfo.length > 0){
            		_.forEach(dti.dossiersTransactionsInfo, function(dtiAux){
            			if(!isRep){
             				isRep = ((dtiAux.dossierTransactionDefinition && dtiAux.dossierTransactionDefinition.transactionType && (dtiAux.dossierTransactionDefinition.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[12].id ||dtiAux.dossierTransactionDefinition.transactionType.transactionSubtype == apiAdmin.transactionSubtypes[14].id))?true:false);	
            			}
            		});
            	}
            	return isRep;
            }
            
            vm.isDisabled = function (dossierTransactionInfo) {
                if (vm.data.dossierTransactionInfo.graphNodeId == dossierTransactionInfo.graphNodeId) {
                    return false;
                } else {
                    if (!vm.data.level || vm.data.level == 1) {
                        return false;
                    } else if (vm.data.dossierTransactionInfo.active) {
                        return false;
                    } else {
                        if (vm.getAfterChecked(vm.data.dossierTransactionInfoOrig, dossierTransactionInfo)) {
                            return false;
                        } else {
                        	if(vm.getAfterClosed(vm.data.dossierTransactionInfoOrig, dossierTransactionInfo)){
                        		return false;
                            
                        	}else if(dossierTransactionInfo.graphNodeType == "SelectAllOpen" && isReparation(dossierTransactionInfo)){
                                return false;
                            }else{
                                dossierTransactionInfo.active = false;
                                return true;
                            }
                        }
                    }
                }
            };

            vm.getAfterClosed = function (dossierTransactionInfoOrig, dossierTransactionInfo) {
                var disabled = true;
                if (dossierTransactionInfoOrig.graphNodeId == dossierTransactionInfo.graphNodeId) {
                    disabled = dossierTransactionInfo.active;
                } else {
                    var parent = vm.getParent(vm.data.dossierTransactionInfoOrig, dossierTransactionInfo);
                    if (parent && parent.graphNodeType && _.contains(['SelectMultipleOpen', 'SelectOne', 'SelectAllOpen'], parent.graphNodeType)) {
                    	parent = vm.getParent(vm.data.dossierTransactionInfoOrig, parent);
                    	if(parent && parent.graphNodeType && _.contains(['SelectMultipleClose', 'SelectAllClose', 'SelectAllOpen'], parent.graphNodeType)){
                    	    disabled = true;
                    	}else{
                    		disabled = false;
                    	}
                    } else {
                        disabled = false;
                    }
                }
                return disabled;
            };

            vm.getAfterChecked = function (dossierTransactionInfoOrig, dossierTransactionInfo) {
                var disabled = true;
                if (dossierTransactionInfoOrig.graphNodeId == dossierTransactionInfo.graphNodeId) {
                    disabled = dossierTransactionInfo.active;
                } else {
                    var parent = vm.getParent(vm.data.dossierTransactionInfoOrig, dossierTransactionInfo);
                    if (parent && !vm.selectType(3, parent, false)) {
                        disabled = vm.getAfterChecked(vm.data.dossierTransactionInfoOrig, parent);
                    } else {
                        disabled = parent.active;
                    }
                }
                return disabled;
            };

            vm.getParent = function (dossierTransactionInfoOrig, dossierTransactionInfo) {
                var object;
                if (dossierTransactionInfoOrig) {
                    if (dossierTransactionInfoOrig.graphNodeId == dossierTransactionInfo.graphNodeId) {
                        object = dossierTransactionInfo;
                    } else {
                        _.forEach(dossierTransactionInfoOrig.dossiersTransactionsInfo, function (value, key) {
                            if (!object) {
                                if (value.graphNodeId == dossierTransactionInfo.graphNodeId) {
                                    object = dossierTransactionInfoOrig;
                                } else {
                                    object = vm.getParent(value, dossierTransactionInfo);
                                }
                            }
                        });
                    }
                }
                return object;
            };

            vm.isParallelCloseFunction = function (dossierTransactionInfo) {
                if (dossierTransactionInfo && dossierTransactionInfo.pending && (dossierTransactionInfo.graphNodeType == 'SelectMultipleClose' || dossierTransactionInfo.graphNodeType == 'SelectAllClose')) {
                    return true;
                } else {
                    if (dossierTransactionInfo && dossierTransactionInfo.dossiersTransactionsInfo && dossierTransactionInfo.dossiersTransactionsInfo.length == 1) {
                        return vm.isParallelCloseFunction(dossierTransactionInfo.dossiersTransactionsInfo[0]);
                    } else {
                        return false;
                    }
                }
            }

			this.$onInit = function () {
				if(vm.data && vm.data.dossierTransactionInfo){
					if(!vm.data.level){
						vm.data.level = 1;
					}
					vm.nextLevel = vm.data.level + 1;
				}else {
					if(vm.dossier && vm.transaction && vm.dossierTransactionInfo && vm.dossierTransactionInfoOrig && vm.level){
						vm.data = {
							dossier: vm.dossier,
		                    transaction: vm.transaction,	
							dossierTransactionInfo: vm.dossierTransactionInfo,
							dossierTransactionInfoOrig: vm.dossierTransactionInfoOrig, 
							level: vm.level
						}
						vm.nextLevel = vm.data.level + 1;
					}else{
						vm.data = undefined;
					}
				}
				vm.languageColumn = Language.getActiveColumn();
				vm.descriptionLanguageColumn = "descriptionL" + vm.languageColumn.substr(1, vm.languageColumn.length);
            	vm.showUsersProfiles = (($rootScope.app && $rootScope.app.configuration &&  $rootScope.app.configuration.assign_responsible_user && $rootScope.app.configuration.assign_responsible_user.dossierTransaction && 
					($rootScope.app.configuration.assign_responsible_user.dossierTransaction == 'Obligatoria' || $rootScope.app.configuration.assign_responsible_user.dossierTransaction == 'Opcional')) || $rootScope.esetMode);
				vm.amendmentFiscalizationTransactionTypeId = (($rootScope.app && $rootScope.app.configuration &&  $rootScope.app.configuration.secretary_properties && $rootScope.app.configuration.secretary_properties.fiscalizationAmmendment)?$rootScope.app.configuration.secretary_properties.fiscalizationAmmendment:undefined);
				vm.amendmentFiscalizationTransactionTypeFreeId = (($rootScope.app && $rootScope.app.configuration &&  $rootScope.app.configuration.secretary_properties && $rootScope.app.configuration.secretary_properties.fiscalizationAmmendmentFree)?$rootScope.app.configuration.secretary_properties.fiscalizationAmmendmentFree:undefined);
				if(vm.data && vm.data.dossierTransactionInfo){
					vm.isParallelClose = vm.isParallelCloseFunction(vm.data.dossierTransactionInfo);
					if(vm.data && !vm.data.dossierTransactionInfoOrig){
						vm.data.dossierTransactionInfoOrig = vm.data.dossierTransactionInfo;
					}
					if(vm.data && vm.data.dossier && vm.data.dossier.dossierTramitationType && vm.data.dossier.dossierTramitationType.id){
						vm.transactionsTypes = $linq(GlobalDataFactory.transactiontypes).where("x => x.tramitationType.id == " + vm.data.dossier.dossierTramitationType.id + " && x.finallyType == false && x.active == 1").toArray();	
					}
					vm.addProfileFree(vm.data.dossierTransactionInfo);
            	}
			}
			
        }],
        bindings: {
            data: '=?',
			dossier: '=?',
            transaction: '=?',	
			dossierTransactionInfo: '=?',
			dossierTransactionInfoOrig: '=?', 
			level: '=?'
        }
    })