angular
    .module('annexaApp')
    .component('annexaBoxAdminCreateTransactions',{
        templateUrl: './components/tram/annexa-box-admin-create-transactions/annexa-box-admin-create-transactions.html',
        controller:['Language', '$rootScope', 'AnnexaObjectBoxFactory', 'GlobalDataFactory', '$filter', '$scope', '$state', 'AnnexaFormlyFactory', 'CommonAdminModals', 'DialogsFactory', 'apiAdmin', 'RestService', 'HelperService', 'TramNewFactory', 'CommonService',
        function(Language, $rootScope, AnnexaObjectBoxFactory, GlobalDataFactory, $filter, $scope, $state, AnnexaFormlyFactory, CommonAdminModals, DialogsFactory, apiAdmin, RestService, HelperService, TramNewFactory, CommonService) {
            var vm = this;
            vm.languageColumn = Language.getActiveColumn();
			vm.renderAssignationType = function(item){
				if(item){
					return $filter('translate')('global.literals.typeTram' + item.assignationTypeTram) + ((item.assignationTypeTram === 'INHERITED')?' (' + $filter('translate')('global.literals.inheritedType' + item.inheritedAssignationTypeTram) + ')':'');
				}else{
					return "";
				}
			}
			
			vm.renderTransactionLiteral = function(transactionId){
				var transaction = $linq(GlobalDataFactory.transactiontypes).firstOrDefault(undefined, "x => x.id == " + transactionId);
				if(transaction) {
					return transaction[vm.languageColumn];
				} else {
					return "";
				}
			}
			
			vm.renderTramTemplates = function(tramTemplates){
				var literal = "";
				var first = true;
				angular.forEach(tramTemplates, function (value, key) {
					if(first) {
						literal += value.tramTemplate[vm.languageColumn];
						first = false;
					} else {
						literal += ", " + value.tramTemplate[vm.languageColumn];
					}
                });
				return literal;
			}
			
			vm.renderSubprocedures = function(subprocedures){
				var literal = "";
				var first = true;
				angular.forEach(subprocedures, function (value, key) {
					if(first) {
						literal += value.subprocedure[vm.languageColumn];
						first = false;
					} else {
						literal += ", " + value.subprocedure[vm.languageColumn];
					}
                });
				return literal;
			}
			
			vm.saveNewCreateTransaction = function(){
				var self = this;
				if(this.annexaFormly && this.annexaFormly.model && this.annexaFormly.model.modal_body){
					var model = this.annexaFormly.model.modal_body;
					var tramTemplateField = $linq(this.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'tramTemplate'");
					var ct = {
						transactionType: $linq(GlobalDataFactory.transactiontypes).firstOrDefault(undefined, "x => x.id == " + model.transactionType),
						assignationTypeTram: model.assignationTypeTram,
						preSpel: model.preSpel,
						postSpel: model.postSpel,
						descriptionNoPreSpel: model.descriptionNoPreSpel,
						descriptionNoPostSpel: model.descriptionNoPostSpel
					}
					if(ct.transactionType && ct.transactionType.id){
						ct.transactionType = {id:ct.transactionType.id, language1:ct.transactionType.language1, language2:ct.transactionType.language2, language3:ct.transactionType.language3}
					}
					if(model.subprocedures && model.subprocedures.length > 0) {
						ct.subprocedures = [];
						angular.forEach(model.subprocedures, function (value, key) {
							ct.subprocedures.push({subprocedure:{id:value}});
                        });
					}
					if(tramTemplateField && tramTemplateField.data && tramTemplateField.data.templates && tramTemplateField.data.templates.length > 0) {
						ct.tramTemplates = tramTemplateField.data.templates;
					}					
					if(model.assignationTypeTram === 'GUIDED'){
						ct.profiles = [];
						angular.forEach(model.profiles, function (value, key) {
							ct.profiles.push({profile:{id:value}});
                        });
						ct.users = [];
						angular.forEach(model.users, function (value, key) {
							ct.users.push({user:{id:value}});
                        });
					} else if(model.assignationTypeTram === 'INHERITED') {
						ct.inheritedAssignationTypeTram = model.inheritedAssignationTypeTram;
					}
					if(vm.isNew){
						if(ct.subprocedures && ct.subprocedures.length > 0) {
							angular.forEach(ct.subprocedures, function (value, key) {
								value.subprocedure = $linq(GlobalDataFactory.procedures).firstOrDefault(undefined, "x => x.id == " + value.subprocedure.id);
								if(value.subprocedure){
									value.subprocedure = {id:value.subprocedure.id, language1:value.subprocedure.language1, language2:value.subprocedure.language2, language3:value.subprocedure.language3}
								}
	                        });
						}
						vm.createTransactions.push(ct);
						self.close();
					}else{
						var url = "";
						if(vm.type === 'PROCEDURE'){
							url = "./api/procedure/procedureCreateTransactionType/";
							ct.procedure = {id:((vm.object && vm.object.id)?vm.object.id:undefined)};
						}
						if(url){
							RestService.insert(url, ct).then(function (data) {
								data = JSOG.decode(data);
								if(data.subprocedures && data.subprocedures.length > 0) {
									angular.forEach(data.subprocedures, function (value, key) {
										value.subprocedure = $linq(GlobalDataFactory.procedures).firstOrDefault(undefined, "x => x.id == " + value.subprocedure.id);
										if(value.subprocedure){
											value.subprocedure = {id:value.subprocedure.id, language1:value.subprocedure.language1, language2:value.subprocedure.language2, language3:value.subprocedure.language3}
										}
			                        });
								}
								vm.createTransactions.push(data);
				        		self.close();
				            }).catch(function (error) {
				            	DialogsFactory.error($filter('translate')('global.errors.unknownNew'));
				            });
						}
					}
				}
			}
			
			vm.newCreateTransaction = function(){
				var modal = angular.copy(CommonAdminModals.createTransactionAdd);
				var transactionTypeField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'transactionType'");
				if(transactionTypeField) {
					transactionTypeField.templateOptions.labelProp = Language.getActiveColumn();
					if(vm.createTransactions && vm.createTransactions.length > 0) {
						var types = $linq(GlobalDataFactory.transactiontypes).orderBy("x => x." + Language.getActiveColumn(), HelperService.caseInsensitiveOrNullComparer).where("x => x.active == 1").toArray();
						transactionTypeField.templateOptions.options = $linq(types).except(vm.createTransactions, function(x,y){
                            if(x.transactionType && x.transactionType.id == y.id){
                                return true;
                            }
                            return false;
                        }).toArray();
					} else {
						transactionTypeField.templateOptions.options = $linq(GlobalDataFactory.transactiontypes).orderBy("x => x." + Language.getActiveColumn(), HelperService.caseInsensitiveOrNullComparer).where("x => x.active == 1").toArray();
					}
				}
				var addPreExpressionField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'addPreExpression'");
				if(addPreExpressionField) {
					addPreExpressionField.templateOptions.executeFunction = function() {
						var params = [];
						params.push({name:'dossier', type:{id:'Dossier',name:'Dossier'}, id:''});
						params.push({name:'transaction', type:{id:'DossierTransaction',name:'DossierTransaction'}, id:''});
						params.push({name:'user', type:{id:'User',name:'User'}, id:$rootScope.LoggedUser.id});
						CommonService.executeExpression(((modal && modal.annexaFormly && modal.annexaFormly.model.modal_body.preSpel)?modal.annexaFormly.model.modal_body.preSpel:undefined), params);	
					}
				}
				var addPostExpressionField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'addPostExpression'");
				if(addPostExpressionField) {
					addPostExpressionField.templateOptions.executeFunction = function() {
						var params = [];
						params.push({name:'dossier', type:{id:'Dossier',name:'Dossier'}, id:''});
						params.push({name:'transaction', type:{id:'DossierTransaction',name:'DossierTransaction'}, id:''});
						params.push({name:'user', type:{id:'User',name:'User'}, id:$rootScope.LoggedUser.id});
						CommonService.executeExpression(((modal && modal.annexaFormly && modal.annexaFormly.model.modal_body.postSpel)?modal.annexaFormly.model.modal_body.postSpel:undefined), params);	
					}
				}
				setModalTemplates(modal, []);
				AnnexaFormlyFactory.showModal('modalCreateTransactionAdd', modal, vm.saveNewCreateTransaction, false);
			}
			
			vm.deleteCreateTransaction = function(item, index){
				DialogsFactory.confirm('global.literals.deleteCreateTransaction', 'global.literals.deleteCreateTransactionConfirm').then(function (data) {
					if(vm.isNew){
						vm.createTransactions.splice(index, 1);
					}else{
						if(item && item.id){
							var url = "";
							if(vm.type === 'PROCEDURE'){
								url = "./api/procedure/procedureCreateTransactionType/";
							}
							if(url){
								RestService.delete(url + item.id).then(function (data) {
				        			vm.createTransactions.splice(index, 1);
					            }).catch(function (error) {
					            	DialogsFactory.error($filter('translate')('global.errors.unknownDelete'));
					            });
							}
						}else{
							vm.createTransactions.splice(index, 1);
						}
					}	
	            }).catch(function (data) {
		               //Empty
		        });
			}
			
			vm.editCreateTransaction = function(item){
				var openModal = function(item, itemAux){
					var modal = angular.copy(CommonAdminModals.createTransactionAdd);
					modal.temp = {item:item, itemAux: itemAux};
					var transactionTypeField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'transactionType'");
					if(transactionTypeField) {
						transactionTypeField.templateOptions.labelProp = Language.getActiveColumn();
						if(vm.createTransactions && vm.createTransactions.length > 0) {
							var types = $linq(GlobalDataFactory.transactiontypes).orderBy("x => x." + Language.getActiveColumn(), HelperService.caseInsensitiveOrNullComparer).where("x => x.active == 1").toArray();
							transactionTypeField.templateOptions.options = $linq(types).except(vm.createTransactions, function(x,y){
                                if(x.transactionType && x.transactionType.id == y.id && y.id != item.transactionType.id){
                                    return true;
                                }
                                return false;
                            }).toArray();
						} else {
							transactionTypeField.templateOptions.options = $linq(GlobalDataFactory.transactiontypes).orderBy("x => x." + Language.getActiveColumn(), HelperService.caseInsensitiveOrNullComparer).where("x => x.active == 1").toArray();
						}
					}
					var addPreExpressionField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'addPreExpression'");
					if(addPreExpressionField) {
						addPreExpressionField.templateOptions.executeFunction = function() {
							var params = [];
							params.push({name:'dossier', type:{id:'Dossier',name:'Dossier'}, id:''});
							params.push({name:'transaction', type:{id:'DossierTransaction',name:'DossierTransaction'}, id:''});
							params.push({name:'user', type:{id:'User',name:'User'}, id:$rootScope.LoggedUser.id});
							CommonService.executeExpression(((modal && modal.annexaFormly && modal.annexaFormly.model.modal_body.preSpel)?modal.annexaFormly.model.modal_body.preSpel:undefined), params);	
						}
					}
					var addPostExpressionField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'addPostExpression'");
					if(addPostExpressionField) {
						addPostExpressionField.templateOptions.executeFunction = function() {
							var params = [];
							params.push({name:'dossier', type:{id:'Dossier',name:'Dossier'}, id:''});
							params.push({name:'transaction', type:{id:'DossierTransaction',name:'DossierTransaction'}, id:''});
							params.push({name:'user', type:{id:'User',name:'User'}, id:$rootScope.LoggedUser.id});
							CommonService.executeExpression(((modal && modal.annexaFormly && modal.annexaFormly.model.modal_body.postSpel)?modal.annexaFormly.model.modal_body.postSpel:undefined), params);	
						}
					}
					modal.annexaFormly.model = {modal_body: {
						transactionType:((item && item.transactionType)?$linq(GlobalDataFactory.transactiontypes).firstOrDefault(undefined, "x => x.id == " + item.transactionType.id):undefined), 
						assignationTypeTram:((item && item.assignationTypeTram)?item.assignationTypeTram:undefined), 
						inheritedAssignationTypeTram:((item && item.inheritedAssignationTypeTram)?item.inheritedAssignationTypeTram:undefined),
						preSpel: ((item && item.preSpel)?item.preSpel:undefined),
						postSpel: ((item && item.postSpel)?item.postSpel:undefined),
						descriptionNoPreSpel: ((item && item.descriptionNoPreSpel)?item.descriptionNoPreSpel:undefined),
						descriptionNoPostSpel: ((item && item.descriptionNoPostSpel)?item.descriptionNoPostSpel:undefined)
					}};
					
					if(item.profiles && item.profiles.length > 0) {
						modal.annexaFormly.model.modal_body.profiles = [];
						angular.forEach(item.profiles, function (value, key) {
							modal.annexaFormly.model.modal_body.profiles.push(value.profile.id);
                        });
					}
					
					if(item.users && item.users.length > 0) {
						modal.annexaFormly.model.modal_body.users = [];
						angular.forEach(item.users, function (value, key) {
							modal.annexaFormly.model.modal_body.users.push(value.user.id);
                        });
					}
					
					if(item.subprocedures && item.subprocedures.length > 0) {
						modal.annexaFormly.model.modal_body.subprocedures = [];
						angular.forEach(item.subprocedures, function (value, key) {
							modal.annexaFormly.model.modal_body.subprocedures.push(value.subprocedure.id);
                        });
					}
					
					setModalTemplates(modal, ((item && item.tramTemplates)?item.tramTemplates:[]), item.id);
					
					AnnexaFormlyFactory.showModal('modalCreateTransactionEdit', modal, vm.saveEditCreateTransaction, false);
				}
				if(!vm.isNew && item && item.id){
					var url = "";
					if(vm.type === 'PROCEDURE'){
						url = "ProcedureCreateTransactionType";
					}
					if(url){
						RestService.findOne(url, item.id).then(function (data) {
      		        		openModal(JSOG.decode(data.data), item);
			            }).catch(function (error) {
		            		DialogsFactory.error($filter('translate')('global.errors.unknownUpdate'));
			            });
					}else{
						DialogsFactory.error($filter('translate')('global.errors.unknownUpdate'));
					}
				}else{
					openModal(item);
				}
			}
			
			vm.saveEditCreateTransaction = function(){
				var self = this;
				if(this.annexaFormly && this.annexaFormly.model && this.annexaFormly.model.modal_body && this.temp && this.temp.item){
					var model = this.annexaFormly.model.modal_body;
					var tramTemplateField = $linq(this.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'tramTemplate'");
					this.temp.item.transactionType = $linq(GlobalDataFactory.transactiontypes).firstOrDefault(undefined, "x => x.id == " + ((model.transactionType && model.transactionType.id)? model.transactionType.id: model.transactionType));
					if(this.temp.item.transactionType && this.temp.item.transactionType.id){
						this.temp.item.transactionType = {id:this.temp.item.transactionType.id, language1:this.temp.item.transactionType.language1, language2:this.temp.item.transactionType.language2, language3:this.temp.item.transactionType.language3};
					}
					this.temp.item.assignationTypeTram = model.assignationTypeTram;
					this.temp.item.preSpel = model.preSpel;
					this.temp.item.postSpel = model.postSpel;
					this.temp.item.descriptionNoPreSpel = model.descriptionNoPreSpel;
					this.temp.item.descriptionNoPostSpel = model.descriptionNoPostSpel;
					if(model.assignationTypeTram === 'GUIDED'){
						var prof = [];
						angular.forEach(model.profiles, function (value, key) {
							prof.push({profile:{id:value}});
                        });
						this.temp.item.profiles = prof;
						var usr = [];
						angular.forEach(model.users, function (value, key) {
							usr.push({user:{id:value}});
                        });
						this.temp.item.users = usr;
						this.temp.item.inheritedAssignationTypeTram = undefined;
					} else if(model.assignationTypeTram === 'INHERITED') {
						this.temp.item.inheritedAssignationTypeTram = model.inheritedAssignationTypeTram;
						this.temp.item.profiles = undefined;
						this.temp.item.users = undefined;
					} else if(model.assignationTypeTram === 'NONGUIDED') {
						this.temp.item.inheritedAssignationTypeTram = undefined;
						this.temp.item.profiles = undefined;
						this.temp.item.users = undefined;
					}

					if(model.subprocedures && model.subprocedures.length > 0) {
						var subprocedures = [];
						angular.forEach(model.subprocedures, function (value, key) {
							subprocedures.push({subprocedure:{id:value}});
                        });
						this.temp.item.subprocedures = subprocedures;
					}
					
					if(tramTemplateField && tramTemplateField.data && tramTemplateField.data.templates && tramTemplateField.data.templates.length > 0) {
						this.temp.item.tramTemplates = tramTemplateField.data.templates;
					}	
					
					if(vm.isNew){
						if(this.temp.item.subprocedures && this.temp.item.subprocedures.length > 0) {
							angular.forEach(this.temp.item.subprocedures, function (value, key) {
								value.subprocedure = $linq(GlobalDataFactory.procedures).firstOrDefault(undefined, "x => x.id == " + value.subprocedure.id);
								if(value.subprocedure){
									value.subprocedure = {id:value.subprocedure.id, language1:value.subprocedure.language1, language2:value.subprocedure.language2, language3:value.subprocedure.language3}
								}
	                        });
						}
						self.close();
					}else{
						var url = "";
						if(vm.type === 'PROCEDURE'){
							url = "./api/procedure/procedureCreateTransactionType/";
						}
						if(url){
							RestService.update(url + this.temp.item.id, this.temp.item).then(function (data) {
								if(self.temp.itemAux){
									self.temp.itemAux.transactionType = self.temp.item.transactionType;
									self.temp.itemAux.assignationTypeTram = model.assignationTypeTram;
									self.temp.itemAux.inheritedAssignationTypeTram = model.inheritedAssignationTypeTram;
									self.temp.itemAux.preSpel = model.preSpel;
									self.temp.itemAux.postSpel = model.postSpel;
									self.temp.itemAux.descriptionNoPreSpel = model.descriptionNoPreSpel;
									self.temp.itemAux.descriptionNoPostSpel = model.descriptionNoPostSpel;
									self.temp.itemAux.profiles = [];
									self.temp.itemAux.users = [];
									if(model.assignationTypeTram === 'GUIDED'){
										self.temp.itemAux.profiles = self.temp.item.profiles;
										self.temp.itemAux.users = self.temp.item.users;
									}
									self.temp.itemAux.tramTemplates = self.temp.item.tramTemplates;
									self.temp.itemAux.subprocedures = [];
									if(self.temp.item.subprocedures && self.temp.item.subprocedures.length > 0) {
										angular.forEach(self.temp.item.subprocedures, function (value, key) {
											var proc = $linq(GlobalDataFactory.procedures).firstOrDefault(undefined, "x => x.id == " + value.subprocedure.id);
											if(proc){
												proc = {id:proc.id, language1:proc.language1, language2:proc.language2, language3:proc.language3}
											}
											self.temp.itemAux.subprocedures.push({subprocedure:proc});
				                        });
									}
								}
				        		self.close();
				            }).catch(function (error) {
				            	DialogsFactory.error($filter('translate')('global.errors.unknownUpdate'));
				            });
						}
					}
				}
			}
			
			vm.sort = function(item, partFrom, partTo, indexFrom, indexTo){
				if(partTo) {
					_.forEach(partTo, function(value, key){
                        value.viewOrder = key + 1;
                    });
					RestService.update('./api/procedure/procedureCreateTransactionType/order', partTo)
	                .then(function (data) {
	                    //Empty
	                }).catch(function (error) {
	                    console.error(error);
	                });
				}
            }

			
			var setModalTemplates = function(modal, actualTemplates, idpctt) {
				TramNewFactory.getTramTemplates(-1, ((vm.object && vm.object.id)?vm.object.id:-1)).then(function (data) {
					var tramTemplateField = $linq(modal.annexaFormly.fields[0].fieldGroup).firstOrDefault(undefined, "x => x.key == 'tramTemplate'");
					if(tramTemplateField) {
						var tram_templates = angular.copy(TramNewFactory.TramTemplates);
						_.forEach(tram_templates, function (value, key) {
			                if(vm.object && vm.object.id && $linq(value.procedures).contains(vm.object, "(x, y) => x.id == y.id")) {
			                	value.literal = '<strong>' + value[Language.getActiveColumn()] + '</strong>';
			                } else {
			                	value.literal = value[Language.getActiveColumn()];
			                }
			            });
						tramTemplateField.data.templatesOptions = tram_templates;
						tramTemplateField.data.isNew = vm.isNew;
						tramTemplateField.data.templates = actualTemplates;
					}
	            });
			}
			
        	this.$onInit = function () {
				if(!vm.isNew){
					vm.isNew = false;
				}
				if(!vm.createTransactions){
					vm.createTransactions = [];
				}
            }
        }],
        bindings: {
            object: '=',
            isNew: '=?',
            type:'@',
			createTransactions: '='
        }
    })